<template>
  <section>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-client' }">
              {{ resourceNamePlural }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Create {{ resourceName }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          variant="primary"
          :to="{ name: 'browse-client' }"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Manage {{ resourceNamePlural }}
        </b-button>
      </b-col>
    </b-row>

    <!-- companies -->
    <b-row v-if="adminAccess">
      <b-col md="4">
        <root-company-select
          class="mb-2"
          load-default
          :load-company="selectedCompany"
          @setCompany="setCompanyId"
        />
      </b-col>
    </b-row>

    <b-card class="p-1">
      <b-card-title class="pl-1 border-left-primary border-left-3">
        Add New {{ resourceName }}
      </b-card-title>
      <!-- form -->
      <validation-observer
        ref="resourceForm"
        #default="{invalid, dirty}"
      >
        <b-form @submit.prevent="createClient">
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <b-form-group
                label="Name of Client"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name of Client"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="name"
                    v-model="clientData.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Name of Client"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- address_1 -->
            <b-col cols="12">
              <b-form-group
                label="Address (Line 1)"
                label-for="address_1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address (Line 1)"
                  vid="address_1"
                >
                  <b-form-input
                    id="address_1"
                    v-model="clientData.address_1"
                    name="address_1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address (Line 1)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- address_2 -->
            <b-col cols="12">
              <b-form-group
                label="Address (Line 2)"
                label-for="address_2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address (Line 2)"
                  vid="address_2"
                >
                  <b-form-input
                    id="address_2"
                    v-model="clientData.address_2"
                    name="address_2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Address (Line 2)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- city -->
            <b-col cols="6">
              <b-form-group
                label="City"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  vid="city"
                >
                  <b-form-input
                    id="city"
                    v-model="clientData.city"
                    name="city"
                    :state="errors.length > 0 ? false:null"
                    placeholder="City"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- state/province -->
            <b-col cols="6">
              <b-form-group
                label="State/Province"
                label-for="province"
              >
                <validation-provider
                  #default="{ errors }"
                  name="State/Province"
                  vid="province"
                >
                  <b-form-input
                    id="province"
                    v-model="clientData.province"
                    name="province"
                    :state="errors.length > 0 ? false:null"
                    placeholder="State/Province"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- phone_number -->
            <b-col cols="6">
              <b-form-group
                label="Phone Number"
                label-for="phone_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone Number"
                  vid="phone_number"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="clientData.phone_number"
                    name="phone_number"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Phone Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit -->
            <b-col
              cols="12"
              class="mt-1 text-right"
            >
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || !dirty"
              >
                Create
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard,
  BFormGroup, BFormInput, BForm, BButton, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import flatPickr from 'vue-flatpickr-component'
import useJwt from '@/auth/jwt/useJwt'
import RootCompanySelect from '@/views/components/root-company-select/RootCompanySelect.vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardTitle,

    // validations
    ValidationProvider,
    ValidationObserver,

    // company picker
    RootCompanySelect,
  },
  data() {
    return {
      resourceName: 'Client',
      resourceNamePlural: 'Clients',
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      userData: JSON.parse(localStorage.getItem('userData')),

      // form data
      selectedCompany: null,
      clientData: {
        name: '',
        address_1: '',
        address_2: '',
        city: '',
        province: '',
        phone_number: '',
      },
      required,
    }
  },
  computed: {

  },
  created() {
    if (this.userData.role.id === 1) {
      this.adminAccess = true
      this.adminSuffix = 'AsRoot'
      if (this.$route.params.companyId) {
        this.setCompanyId(this.$route.params.companyId)
      }
    }
  },
  methods: {
    createClient() {
      if (this.adminAccess) {
        // add company id if root user
        this.clientData.companyId = this.selectedCompany
      }

      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()
          if (this.adminAccess) {
            this.clientData.companyId = this.selectedCompany
          }
          useJwt[`createClient${this.adminSuffix}`](this.clientData)
            .then(response => {
              const resourceData = response.data.data

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${resourceData.name} Created`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Client created successfully.',
                },
              })
              this.$router.push({ name: 'view-client', params: { id: resourceData.id } })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: error.response.data.error ? error.response.data.error : 'Error',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: error.response.data.details || '',
                  },
                })
              }
            })
        }
      })
    },
    setCompanyId(companyId) {
      if (companyId) {
        this.selectedCompany = companyId
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
